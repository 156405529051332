.itaswidget-wrapper {
  width: calc(100% - 1rem);
}
.itas-widget,
itaswidget {
  /*Grid sizes*/
  /*Namespace*/
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 21px;
  font-weight: 400;
  font-family: 'Open Sans' Arial;
  width: 100%;
  position: relative;
  min-height: 200px;
  line-height: 1.58;
  padding: 1em;
  border: 1px solid #d7d7d7;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.itas-widget button,
itaswidget button,
.itas-widget textarea,
itaswidget textarea,
.itas-widget input,
itaswidget input,
.itas-widget table,
itaswidget table {
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  outline: none;
}
.itas-widget p,
itaswidget p {
  margin: 0;
  padding: 0;
}
.itas-widget .input,
itaswidget .input,
.itas-widget .range,
itaswidget .range {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 10px;
}
.itas-widget .input.align-center input,
itaswidget .input.align-center input,
.itas-widget .range.align-center input,
itaswidget .range.align-center input {
  text-align: center;
}
.itas-widget .input.align-right input,
itaswidget .input.align-right input,
.itas-widget .range.align-right input,
itaswidget .range.align-right input {
  text-align: right;
}
.itas-widget .input.align-right [class^="icon-"],
itaswidget .input.align-right [class^="icon-"],
.itas-widget .range.align-right [class^="icon-"],
itaswidget .range.align-right [class^="icon-"] {
  left: 20px;
  right: auto;
}
.itas-widget .input.align-right [class^="icon-"] + input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
itaswidget .input.align-right [class^="icon-"] + input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
.itas-widget .range.align-right [class^="icon-"] + input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
itaswidget .range.align-right [class^="icon-"] + input:not([type="radio"]):not([type="checkbox"]):not([type="range"]) {
  padding-right: 0.6em;
  padding-left: 50px;
}
.itas-widget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
itaswidget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
.itas-widget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
itaswidget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"]) {
  width: 100%;
  font-weight: 300;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.75em 0.5em;
  -moz-appearance: textfield;
}
.itas-widget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"])::-webkit-inner-spin-button,
itaswidget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"])::-webkit-inner-spin-button,
.itas-widget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"])::-webkit-inner-spin-button,
itaswidget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"])::-webkit-inner-spin-button,
.itas-widget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"])::-webkit-outer-spin-button,
itaswidget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"])::-webkit-outer-spin-button,
.itas-widget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"])::-webkit-outer-spin-button,
itaswidget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"])::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.itas-widget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"]):-moz-read-only, itaswidget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"]):-moz-read-only, .itas-widget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"]):-moz-read-only, itaswidget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"]):-moz-read-only {
  background: rgba(255, 255, 255, 0.2);
}
.itas-widget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"]):read-only,
itaswidget .input input:not([type="radio"]):not([type="checkbox"]):not([type="range"]):read-only,
.itas-widget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"]):read-only,
itaswidget .range input:not([type="radio"]):not([type="checkbox"]):not([type="range"]):read-only {
  background: rgba(255, 255, 255, 0.2);
}
.itas-widget .input.border-bottom input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
itaswidget .input.border-bottom input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
.itas-widget .range.border-bottom input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
itaswidget .range.border-bottom input:not([type="radio"]):not([type="checkbox"]):not([type="range"]) {
  width: 100%;
  background: transparent;
  padding: 0.5em 0;
  border-bottom: 1px solid rgba(233, 90, 90, 0.25);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.itas-widget .input [class^="icon-"],
itaswidget .input [class^="icon-"],
.itas-widget .range [class^="icon-"],
itaswidget .range [class^="icon-"] {
  position: absolute;
  top: 15px;
  right: 20px;
}
.itas-widget .input [class^="icon-"] + input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
itaswidget .input [class^="icon-"] + input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
.itas-widget .range [class^="icon-"] + input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
itaswidget .range [class^="icon-"] + input:not([type="radio"]):not([type="checkbox"]):not([type="range"]) {
  padding-right: 50px;
}
.itas-widget .input label,
itaswidget .input label,
.itas-widget .range label,
itaswidget .range label {
  display: block;
  width: 100%;
  font-size: 0.7em;
  padding-top: 0.5em;
  font-weight: bold;
  color: currentColor;
}
.itas-widget .input label span,
itaswidget .input label span,
.itas-widget .range label span,
itaswidget .range label span {
  opacity: 0.8;
  font-size: 0.9em;
  font-style: italic;
}
.itas-widget .radio,
itaswidget .radio,
.itas-widget .checkbox,
itaswidget .checkbox {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 10px !important;
}
.itas-widget .radio input[type=radio],
itaswidget .radio input[type=radio],
.itas-widget .checkbox input[type=radio],
itaswidget .checkbox input[type=radio],
.itas-widget .radio input[type=checkbox],
itaswidget .radio input[type=checkbox],
.itas-widget .checkbox input[type=checkbox],
itaswidget .checkbox input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  margin: 0;
}
.itas-widget .radio input[type=radio]:checked + label:before,
itaswidget .radio input[type=radio]:checked + label:before,
.itas-widget .checkbox input[type=radio]:checked + label:before,
itaswidget .checkbox input[type=radio]:checked + label:before,
.itas-widget .radio input[type=checkbox]:checked + label:before,
itaswidget .radio input[type=checkbox]:checked + label:before,
.itas-widget .checkbox input[type=checkbox]:checked + label:before,
itaswidget .checkbox input[type=checkbox]:checked + label:before {
  background: #7DD076;
  border-color: #7DD076;
}
.itas-widget .radio input[type=radio]:checked + label:after,
itaswidget .radio input[type=radio]:checked + label:after,
.itas-widget .checkbox input[type=radio]:checked + label:after,
itaswidget .checkbox input[type=radio]:checked + label:after,
.itas-widget .radio input[type=checkbox]:checked + label:after,
itaswidget .radio input[type=checkbox]:checked + label:after,
.itas-widget .checkbox input[type=checkbox]:checked + label:after,
itaswidget .checkbox input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.itas-widget .radio input[type=radio]:disabled + label,
itaswidget .radio input[type=radio]:disabled + label,
.itas-widget .checkbox input[type=radio]:disabled + label,
itaswidget .checkbox input[type=radio]:disabled + label,
.itas-widget .radio input[type=checkbox]:disabled + label,
itaswidget .radio input[type=checkbox]:disabled + label,
.itas-widget .checkbox input[type=checkbox]:disabled + label,
itaswidget .checkbox input[type=checkbox]:disabled + label {
  opacity: 0.5;
}
.itas-widget .radio label,
itaswidget .radio label,
.itas-widget .checkbox label,
itaswidget .checkbox label {
  display: block;
  padding: 0 10px 0 40px;
  position: relative;
  font-weight: 300;
  pointer-events: none;
  min-height: 30px;
}
.itas-widget .radio label:before,
itaswidget .radio label:before,
.itas-widget .checkbox label:before,
itaswidget .checkbox label:before {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  padding: 2px;
  position: absolute;
  border: 1px solid #d7d7d7;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease;
}
.itas-widget .radio label:after,
itaswidget .radio label:after,
.itas-widget .checkbox label:after,
itaswidget .checkbox label:after {
  content: '';
  opacity: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 7px;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  width: 15px;
  height: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #fff;
  border-radius: 50%;
}
.itas-widget .checkbox label:before,
itaswidget .checkbox label:before {
  border-radius: 2px;
}
.itas-widget .caption,
itaswidget .caption {
  font-size: 1em;
  font-weight: 300;
  color: currentColor;
  margin: 10px;
}
.itas-widget .ranger,
itaswidget .ranger,
.itas-widget .ranger-fill,
itaswidget .ranger-fill {
  background: rgba(255, 255, 255, 0.1);
  display: block;
  height: 8px;
  width: 100%;
}
.itas-widget .ranger,
itaswidget .ranger {
  position: relative;
  margin: 50px 10px 10px;
}
.itas-widget .ranger-disabled,
itaswidget .ranger-disabled {
  opacity: 0.4;
}
.itas-widget .ranger-fill,
itaswidget .ranger-fill {
  background: #00759c;
  -webkit-box-shadow: 0px 5px 20px -2px rgba(0, 117, 156, 0.7);
  box-shadow: 0px 5px 20px -2px rgba(0, 117, 156, 0.7);
  position: absolute;
  top: 0;
  z-index: 2;
}
.itas-widget .ranger-handle,
itaswidget .ranger-handle {
  display: inline-block;
  width: 2px;
  height: 14px;
  position: absolute;
  top: -3px;
  z-index: 3;
  background: #00759c;
  -webkit-box-shadow: 0px 10px 20px -1px rgba(0, 117, 156, 0.3);
  box-shadow: 0px 10px 20px -1px rgba(0, 117, 156, 0.3);
}
.itas-widget .ranger-handle.ranger-label:before,
itaswidget .ranger-handle.ranger-label:before {
  content: attr(value);
  background: inherit;
  color: #FFF;
  padding: 4px 5px 5px 5px;
  font-size: 13px;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  display: block;
  white-space: nowrap;
  position: absolute;
  top: -8px;
  border-radius: 3px;
}
.itas-widget .ranger-handle.ranger-label:after,
itaswidget .ranger-handle.ranger-label:after {
  content: '';
  display: block;
  position: absolute;
  border-top: 5px solid #00759c;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  top: -9px;
  left: -4px;
}
.itas-widget .ranger-buffer,
itaswidget .ranger-buffer {
  z-index: 1;
  position: absolute;
  top: 0;
  height: 5px;
  background: #d1d1d1;
}
.itas-widget:not(.clean) .cb-items,
itaswidget:not(.clean) .cb-items {
  background: #474b51;
  border-color: transparent;
}
.itas-widget:not(.clean) .cb-items div,
itaswidget:not(.clean) .cb-items div {
  border-color: #53575e !important;
}
.itas-widget:not(.clean) .cb-items div:hover,
itaswidget:not(.clean) .cb-items div:hover {
  background: #7DD076;
  color: white;
}
.itas-widget .choosebumps,
itaswidget .choosebumps {
  width: auto;
  border-color: transparent;
  background: rgba(255, 255, 255, 0.1);
}
.itas-widget .cb-caret,
itaswidget .cb-caret {
  fill: #fff;
}
.itas-widget .cb-selected,
itaswidget .cb-selected {
  background: #7DD076;
  color: white;
}
.itas-widget .table,
itaswidget .table {
  padding: 10px;
}
.itas-widget table,
itaswidget table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  color: #E95A5A;
}
.itas-widget table thead tr,
itaswidget table thead tr {
  border-bottom: 1px solid rgba(233, 90, 90, 0.25);
}
.itas-widget table th,
itaswidget table th {
  padding-bottom: 0.5em;
}
.itas-widget table td,
itaswidget table td {
  padding: 0.3em 0;
}
.itas-widget table tbody tr + tr,
itaswidget table tbody tr + tr {
  border-top: 1px solid rgba(233, 90, 90, 0.1);
}
.itas-widget h1,
itaswidget h1 {
  margin: 10px 10px 1em 10px;
  font-size: 1.3em;
  padding-bottom: 0.5em;
  line-height: 1.58;
}
.itas-widget h1 span,
itaswidget h1 span {
  opacity: 0.8;
  font-size: 0.5em;
  font-style: italic;
}
.itas-widget p,
itaswidget p {
  margin: 0;
  padding: 0;
  font-weight: 300;
}
.itas-widget .small,
itaswidget .small {
  font-size: 0.75em;
}
.itas-widget .fade,
itaswidget .fade {
  opacity: 0.75;
}
.itas-widget .italic,
itaswidget .italic {
  font-style: italic;
}
.itas-widget .center,
itaswidget .center {
  text-align: center;
}
.itas-widget .right,
itaswidget .right {
  text-align: right;
}
.itas-widget hr,
itaswidget hr {
  border: none;
  border-top: 1px solid rgba(239, 135, 135, 0.25);
  margin: 10px;
}
.itas-widget button,
itaswidget button,
.itas-widget a.button,
itaswidget a.button,
.itas-widget input[type="submit"],
itaswidget input[type="submit"] {
  display: inline-block;
  position: relative;
  padding: 0.65em 1.5em;
  margin: 10px;
  background: white;
  font-weight: 300;
  color: #E95A5A;
  cursor: pointer;
  -webkit-transition: background 0.1s ease, text-indent 0.3s ease-out;
  transition: background 0.1s ease, text-indent 0.3s ease-out;
}
.itas-widget button [class^="icon-"],
itaswidget button [class^="icon-"],
.itas-widget a.button [class^="icon-"],
itaswidget a.button [class^="icon-"],
.itas-widget input[type="submit"] [class^="icon-"],
itaswidget input[type="submit"] [class^="icon-"] {
  margin-right: 0.5em;
}
.itas-widget button:not(:disabled):hover,
itaswidget button:not(:disabled):hover,
.itas-widget a.button:not(:disabled):hover,
itaswidget a.button:not(:disabled):hover,
.itas-widget input[type="submit"]:not(:disabled):hover,
itaswidget input[type="submit"]:not(:disabled):hover {
  background: #f7f7f7;
}
.itas-widget button:not(:disabled):active,
itaswidget button:not(:disabled):active,
.itas-widget a.button:not(:disabled):active,
itaswidget a.button:not(:disabled):active,
.itas-widget input[type="submit"]:not(:disabled):active,
itaswidget input[type="submit"]:not(:disabled):active {
  background: #dddddd;
}
.itas-widget button:disabled,
itaswidget button:disabled,
.itas-widget a.button:disabled,
itaswidget a.button:disabled,
.itas-widget input[type="submit"]:disabled,
itaswidget input[type="submit"]:disabled {
  cursor: default;
}
.itas-widget button.round,
itaswidget button.round,
.itas-widget a.button.round,
itaswidget a.button.round,
.itas-widget input[type="submit"].round,
itaswidget input[type="submit"].round {
  border-radius: 30px;
}
.itas-widget button.glow,
itaswidget button.glow,
.itas-widget a.button.glow,
itaswidget a.button.glow,
.itas-widget input[type="submit"].glow,
itaswidget input[type="submit"].glow {
  -webkit-box-shadow: 0px 10px 40px -10px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 10px 40px -10px rgba(51, 51, 51, 0.5);
}
.itas-widget button.clean,
itaswidget button.clean,
.itas-widget a.button.clean,
itaswidget a.button.clean,
.itas-widget input[type="submit"].clean,
itaswidget input[type="submit"].clean {
  padding: 0 1.2rem;
  background: none;
}
.itas-widget button.info,
itaswidget button.info,
.itas-widget a.button.info,
itaswidget a.button.info,
.itas-widget input[type="submit"].info,
itaswidget input[type="submit"].info,
.itas-widget button.default,
itaswidget button.default,
.itas-widget a.button.default,
itaswidget a.button.default,
.itas-widget input[type="submit"].default,
itaswidget input[type="submit"].default {
  background: #00759c;
  color: white;
}
.itas-widget button.info:hover,
itaswidget button.info:hover,
.itas-widget a.button.info:hover,
itaswidget a.button.info:hover,
.itas-widget input[type="submit"].info:hover,
itaswidget input[type="submit"].info:hover,
.itas-widget button.default:hover,
itaswidget button.default:hover,
.itas-widget a.button.default:hover,
itaswidget a.button.default:hover,
.itas-widget input[type="submit"].default:hover,
itaswidget input[type="submit"].default:hover {
  background: #007da6 !important;
}
.itas-widget button.info:active,
itaswidget button.info:active,
.itas-widget a.button.info:active,
itaswidget a.button.info:active,
.itas-widget input[type="submit"].info:active,
itaswidget input[type="submit"].info:active,
.itas-widget button.default:active,
itaswidget button.default:active,
.itas-widget a.button.default:active,
itaswidget a.button.default:active,
.itas-widget input[type="submit"].default:active,
itaswidget input[type="submit"].default:active {
  background: #006283 !important;
}
.itas-widget button.info.glow,
itaswidget button.info.glow,
.itas-widget a.button.info.glow,
itaswidget a.button.info.glow,
.itas-widget input[type="submit"].info.glow,
itaswidget input[type="submit"].info.glow,
.itas-widget button.default.glow,
itaswidget button.default.glow,
.itas-widget a.button.default.glow,
itaswidget a.button.default.glow,
.itas-widget input[type="submit"].default.glow,
itaswidget input[type="submit"].default.glow {
  -webkit-box-shadow: 0px 10px 40px -10px rgba(0, 117, 156, 0.5) !important;
  box-shadow: 0px 10px 40px -10px rgba(0, 117, 156, 0.5) !important;
}
.itas-widget button.done,
itaswidget button.done,
.itas-widget a.button.done,
itaswidget a.button.done,
.itas-widget input[type="submit"].done,
itaswidget input[type="submit"].done,
.itas-widget button.success,
itaswidget button.success,
.itas-widget a.button.success,
itaswidget a.button.success,
.itas-widget input[type="submit"].success,
itaswidget input[type="submit"].success {
  background: #7DD076;
  color: white;
}
.itas-widget button.done:hover,
itaswidget button.done:hover,
.itas-widget a.button.done:hover,
itaswidget a.button.done:hover,
.itas-widget input[type="submit"].done:hover,
itaswidget input[type="submit"].done:hover,
.itas-widget button.success:hover,
itaswidget button.success:hover,
.itas-widget a.button.success:hover,
itaswidget a.button.success:hover,
.itas-widget input[type="submit"].success:hover,
itaswidget input[type="submit"].success:hover {
  background: #84d37e !important;
}
.itas-widget button.done:active,
itaswidget button.done:active,
.itas-widget a.button.done:active,
itaswidget a.button.done:active,
.itas-widget input[type="submit"].done:active,
itaswidget input[type="submit"].done:active,
.itas-widget button.success:active,
itaswidget button.success:active,
.itas-widget a.button.success:active,
itaswidget a.button.success:active,
.itas-widget input[type="submit"].success:active,
itaswidget input[type="submit"].success:active {
  background: #6bc963 !important;
}
.itas-widget button.done.glow,
itaswidget button.done.glow,
.itas-widget a.button.done.glow,
itaswidget a.button.done.glow,
.itas-widget input[type="submit"].done.glow,
itaswidget input[type="submit"].done.glow,
.itas-widget button.success.glow,
itaswidget button.success.glow,
.itas-widget a.button.success.glow,
itaswidget a.button.success.glow,
.itas-widget input[type="submit"].success.glow,
itaswidget input[type="submit"].success.glow {
  -webkit-box-shadow: 0px 10px 40px -2px rgba(125, 208, 118, 0.5) !important;
  box-shadow: 0px 10px 40px -2px rgba(125, 208, 118, 0.5) !important;
}
.itas-widget button.fail,
itaswidget button.fail,
.itas-widget a.button.fail,
itaswidget a.button.fail,
.itas-widget input[type="submit"].fail,
itaswidget input[type="submit"].fail,
.itas-widget button.error,
itaswidget button.error,
.itas-widget a.button.error,
itaswidget a.button.error,
.itas-widget input[type="submit"].error,
itaswidget input[type="submit"].error {
  background: #9C0000;
  color: white;
}
.itas-widget button.fail:hover,
itaswidget button.fail:hover,
.itas-widget a.button.fail:hover,
itaswidget a.button.fail:hover,
.itas-widget input[type="submit"].fail:hover,
itaswidget input[type="submit"].fail:hover,
.itas-widget button.error:hover,
itaswidget button.error:hover,
.itas-widget a.button.error:hover,
itaswidget a.button.error:hover,
.itas-widget input[type="submit"].error:hover,
itaswidget input[type="submit"].error:hover {
  background: #a60000 !important;
}
.itas-widget button.fail:active,
itaswidget button.fail:active,
.itas-widget a.button.fail:active,
itaswidget a.button.fail:active,
.itas-widget input[type="submit"].fail:active,
itaswidget input[type="submit"].fail:active,
.itas-widget button.error:active,
itaswidget button.error:active,
.itas-widget a.button.error:active,
itaswidget a.button.error:active,
.itas-widget input[type="submit"].error:active,
itaswidget input[type="submit"].error:active {
  background: #830000 !important;
}
.itas-widget button.fail.glow,
itaswidget button.fail.glow,
.itas-widget a.button.fail.glow,
itaswidget a.button.fail.glow,
.itas-widget input[type="submit"].fail.glow,
itaswidget input[type="submit"].fail.glow,
.itas-widget button.error.glow,
itaswidget button.error.glow,
.itas-widget a.button.error.glow,
itaswidget a.button.error.glow,
.itas-widget input[type="submit"].error.glow,
itaswidget input[type="submit"].error.glow {
  -webkit-box-shadow: 0px 10px 40px -10px rgba(156, 0, 0, 0.5) !important;
  box-shadow: 0px 10px 40px -10px rgba(156, 0, 0, 0.5) !important;
}
.itas-widget button.warning,
itaswidget button.warning,
.itas-widget a.button.warning,
itaswidget a.button.warning,
.itas-widget input[type="submit"].warning,
itaswidget input[type="submit"].warning {
  background: #ff6119;
  color: white;
}
.itas-widget button.warning:hover,
itaswidget button.warning:hover,
.itas-widget a.button.warning:hover,
itaswidget a.button.warning:hover,
.itas-widget input[type="submit"].warning:hover,
itaswidget input[type="submit"].warning:hover {
  background: #ff6823 !important;
}
.itas-widget button.warning:active,
itaswidget button.warning:active,
.itas-widget a.button.warning:active,
itaswidget a.button.warning:active,
.itas-widget input[type="submit"].warning:active,
itaswidget input[type="submit"].warning:active {
  background: #ff5000 !important;
}
.itas-widget button.warning.glow,
itaswidget button.warning.glow,
.itas-widget a.button.warning.glow,
itaswidget a.button.warning.glow,
.itas-widget input[type="submit"].warning.glow,
itaswidget input[type="submit"].warning.glow {
  -webkit-box-shadow: 0px 10px 40px -10px rgba(255, 97, 25, 0.5) !important;
  box-shadow: 0px 10px 40px -10px rgba(255, 97, 25, 0.5) !important;
}
.itas-widget button.dark,
itaswidget button.dark,
.itas-widget a.button.dark,
itaswidget a.button.dark,
.itas-widget input[type="submit"].dark,
itaswidget input[type="submit"].dark {
  background: #333;
  color: white;
}
.itas-widget button.dark:hover,
itaswidget button.dark:hover,
.itas-widget a.button.dark:hover,
itaswidget a.button.dark:hover,
.itas-widget input[type="submit"].dark:hover,
itaswidget input[type="submit"].dark:hover {
  background: #383838 !important;
}
.itas-widget button.dark:active,
itaswidget button.dark:active,
.itas-widget a.button.dark:active,
itaswidget a.button.dark:active,
.itas-widget input[type="submit"].dark:active,
itaswidget input[type="submit"].dark:active {
  background: #262626 !important;
}
.itas-widget button.dark.glow,
itaswidget button.dark.glow,
.itas-widget a.button.dark.glow,
itaswidget a.button.dark.glow,
.itas-widget input[type="submit"].dark.glow,
itaswidget input[type="submit"].dark.glow {
  -webkit-box-shadow: 0px 10px 40px -10px rgba(51, 51, 51, 0.5) !important;
  box-shadow: 0px 10px 40px -10px rgba(51, 51, 51, 0.5) !important;
}
.itas-widget button.transparent,
itaswidget button.transparent,
.itas-widget a.button.transparent,
itaswidget a.button.transparent,
.itas-widget input[type="submit"].transparent,
itaswidget input[type="submit"].transparent {
  background: transparent;
  color: currentColor;
}
.itas-widget button.transparent:hover,
itaswidget button.transparent:hover,
.itas-widget a.button.transparent:hover,
itaswidget a.button.transparent:hover,
.itas-widget input[type="submit"].transparent:hover,
itaswidget input[type="submit"].transparent:hover {
  background: transparent!important;
}
.itas-widget button.transparent:active,
itaswidget button.transparent:active,
.itas-widget a.button.transparent:active,
itaswidget a.button.transparent:active,
.itas-widget input[type="submit"].transparent:active,
itaswidget input[type="submit"].transparent:active {
  background: rgba(255, 255, 255, 0.1) !important;
}
.itas-widget button text,
itaswidget button text,
.itas-widget a.button text,
itaswidget a.button text,
.itas-widget input[type="submit"] text,
itaswidget input[type="submit"] text,
.itas-widget button .text,
itaswidget button .text,
.itas-widget a.button .text,
itaswidget a.button .text,
.itas-widget input[type="submit"] .text,
itaswidget input[type="submit"] .text {
  display: inline-block;
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}
.itas-widget button .itaswidget-loader,
itaswidget button .itaswidget-loader,
.itas-widget a.button .itaswidget-loader,
itaswidget a.button .itaswidget-loader,
.itas-widget input[type="submit"] .itaswidget-loader,
itaswidget input[type="submit"] .itaswidget-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(20px, 0);
  transform: translate(20px, 0);
  z-index: 4;
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  color: #fff;
}
.itas-widget button .itaswidget-loader:before,
itaswidget button .itaswidget-loader:before,
.itas-widget a.button .itaswidget-loader:before,
itaswidget a.button .itaswidget-loader:before,
.itas-widget input[type="submit"] .itaswidget-loader:before,
itaswidget input[type="submit"] .itaswidget-loader:before {
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  margin: -12.5px;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin 0.5s infinite linear;
  animation: spin 0.5s infinite linear;
}
.itas-widget button .itaswidget-loader.blue,
itaswidget button .itaswidget-loader.blue,
.itas-widget a.button .itaswidget-loader.blue,
itaswidget a.button .itaswidget-loader.blue,
.itas-widget input[type="submit"] .itaswidget-loader.blue,
itaswidget input[type="submit"] .itaswidget-loader.blue {
  color: #00759c;
}
.itas-widget button .itaswidget-loader.red,
itaswidget button .itaswidget-loader.red,
.itas-widget a.button .itaswidget-loader.red,
itaswidget a.button .itaswidget-loader.red,
.itas-widget input[type="submit"] .itaswidget-loader.red,
itaswidget input[type="submit"] .itaswidget-loader.red {
  color: #9C0000;
}
.itas-widget button .itaswidget-loader.green,
itaswidget button .itaswidget-loader.green,
.itas-widget a.button .itaswidget-loader.green,
itaswidget a.button .itaswidget-loader.green,
.itas-widget input[type="submit"] .itaswidget-loader.green,
itaswidget input[type="submit"] .itaswidget-loader.green {
  color: #7DD076;
}
.itas-widget button .itaswidget-loader.black,
itaswidget button .itaswidget-loader.black,
.itas-widget a.button .itaswidget-loader.black,
itaswidget a.button .itaswidget-loader.black,
.itas-widget input[type="submit"] .itaswidget-loader.black,
itaswidget input[type="submit"] .itaswidget-loader.black {
  color: #333;
}
.itas-widget button.itaswidget-loading:before,
itaswidget button.itaswidget-loading:before,
.itas-widget a.button.itaswidget-loading:before,
itaswidget a.button.itaswidget-loading:before,
.itas-widget input[type="submit"].itaswidget-loading:before,
itaswidget input[type="submit"].itaswidget-loading:before {
  content: none!important;
}
.itas-widget button.itaswidget-loading text,
itaswidget button.itaswidget-loading text,
.itas-widget a.button.itaswidget-loading text,
itaswidget a.button.itaswidget-loading text,
.itas-widget input[type="submit"].itaswidget-loading text,
itaswidget input[type="submit"].itaswidget-loading text,
.itas-widget button.itaswidget-loading .text,
itaswidget button.itaswidget-loading .text,
.itas-widget a.button.itaswidget-loading .text,
itaswidget a.button.itaswidget-loading .text,
.itas-widget input[type="submit"].itaswidget-loading .text,
itaswidget input[type="submit"].itaswidget-loading .text {
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  opacity: 0;
}
.itas-widget button.itaswidget-loading .itaswidget-loader,
itaswidget button.itaswidget-loading .itaswidget-loader,
.itas-widget a.button.itaswidget-loading .itaswidget-loader,
itaswidget a.button.itaswidget-loading .itaswidget-loader,
.itas-widget input[type="submit"].itaswidget-loading .itaswidget-loader,
itaswidget input[type="submit"].itaswidget-loading .itaswidget-loader {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}
.itas-widget button.small,
itaswidget button.small,
.itas-widget a.button.small,
itaswidget a.button.small,
.itas-widget input[type="submit"].small,
itaswidget input[type="submit"].small {
  font-size: 0.5em !important;
  padding: 1em 1.3em !important;
}
.itas-widget .button-container,
itaswidget .button-container {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.itas-widget .button-container.right,
itaswidget .button-container.right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.itas-widget .button-container.center,
itaswidget .button-container.center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.itas-widget .button-container.gutter > * + *,
itaswidget .button-container.gutter > * + * {
  margin-left: 5px;
}
.itas-widget .button-container.fill > button,
itaswidget .button-container.fill > button,
.itas-widget .button-container.fill > a.button,
itaswidget .button-container.fill > a.button {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.itas-widget .fadeInLeft,
itaswidget .fadeInLeft {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes pointVertical {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes pointVertical {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
.itas-widget .animation-point-vertical,
itaswidget .animation-point-vertical {
  -webkit-animation: pointVertical 2s infinite ease-out;
  animation: pointVertical 2s infinite ease-out;
}
.itas-widget .inner,
itaswidget .inner {
  padding: 10px;
}
.itas-widget .round,
itaswidget .round {
  border-radius: 5px;
}
.itas-widget .green,
itaswidget .green {
  background: rgba(125, 208, 118, 0.8);
  color: white;
}
.itas-widget .green.glow,
itaswidget .green.glow {
  -webkit-box-shadow: 0px 10px 40px -2px rgba(125, 208, 118, 0.5);
  box-shadow: 0px 10px 40px -2px rgba(125, 208, 118, 0.5);
}
.itas-widget .red,
itaswidget .red {
  background: rgba(156, 0, 0, 0.8);
  color: white;
}
.itas-widget .red.glow,
itaswidget .red.glow {
  -webkit-box-shadow: 0px 10px 40px -10px rgba(156, 0, 0, 0.5);
  box-shadow: 0px 10px 40px -10px rgba(156, 0, 0, 0.5);
}
.itas-widget .white-fade,
itaswidget .white-fade {
  background: rgba(255, 255, 255, 0.1);
}
.itas-widget .border,
itaswidget .border {
  border: 1px solid #eaeaea;
}
.itas-widget .border-bottom,
itaswidget .border-bottom {
  border-bottom: 1px solid #eaeaea;
}
.itas-widget .hide,
itaswidget .hide {
  display: none!important;
}
.itas-widget .hide + hr,
itaswidget .hide + hr {
  display: none!important;
}
.itas-widget .f-y,
itaswidget .f-y,
.itas-widget .f-x,
itaswidget .f-x {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.itas-widget .f-y,
itaswidget .f-y {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.itas-widget .f-x,
itaswidget .f-x {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.itas-widget .f-reverse.f-y,
itaswidget .f-reverse.f-y {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.itas-widget .f-reverse.f-x,
itaswidget .f-reverse.f-x {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.itas-widget .f-wrap,
itaswidget .f-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.itas-widget .f-wrap-reverse,
itaswidget .f-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.itas-widget .f-main-center,
itaswidget .f-main-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.itas-widget .f-main-end,
itaswidget .f-main-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.itas-widget .f-main-around,
itaswidget .f-main-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.itas-widget .f-main-between,
itaswidget .f-main-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.itas-widget .f-minor-center,
itaswidget .f-minor-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.itas-widget .f-minor-start,
itaswidget .f-minor-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.itas-widget .f-minor-end,
itaswidget .f-minor-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.itas-widget .f-minor-base,
itaswidget .f-minor-base {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.itas-widget .f-space-start,
itaswidget .f-space-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
.itas-widget .f-space-end,
itaswidget .f-space-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}
.itas-widget .f-space-center,
itaswidget .f-space-center {
  -ms-flex-line-pack: center;
  align-content: center;
}
.itas-widget .f-space-between,
itaswidget .f-space-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}
.itas-widget .f-space-around,
itaswidget .f-space-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}
.itas-widget .f-center,
itaswidget .f-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.itas-widget .f-end,
itaswidget .f-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}
.itas-widget .f-around,
itaswidget .f-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}
.itas-widget .f-between,
itaswidget .f-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}
.itas-widget .f-self-start,
itaswidget .f-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.itas-widget .f-self-center,
itaswidget .f-self-center {
  -ms-flex-item-align: center;
  align-self: center;
}
.itas-widget .f-self-end,
itaswidget .f-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.itas-widget .f-self-stretch,
itaswidget .f-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.itas-widget .f-self-base,
itaswidget .f-self-base {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}
@media (max-width: 599px) {
  .itas-widget .hidden-xs,
  itaswidget .hidden-xs {
    display: none;
  }
}
@media (max-width: 991px) and (min-width: 600px) {
  .itas-widget .hidden-sm,
  itaswidget .hidden-sm {
    display: none;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .itas-widget .hidden-md,
  itaswidget .hidden-md {
    display: none;
  }
}
@media (min-width: 1200px) {
  .itas-widget .hidden-lg,
  itaswidget .hidden-lg {
    display: none;
  }
}
.itas-widget .f-auto,
itaswidget .f-auto {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.itas-widget .f-xs-1,
itaswidget .f-xs-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
}
.itas-widget .f-xs-2,
itaswidget .f-xs-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
}
.itas-widget .f-xs-3,
itaswidget .f-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}
.itas-widget .f-xs-4,
itaswidget .f-xs-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
}
.itas-widget .f-xs-5,
itaswidget .f-xs-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
}
.itas-widget .f-xs-6,
itaswidget .f-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}
.itas-widget .f-xs-7,
itaswidget .f-xs-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
}
.itas-widget .f-xs-8,
itaswidget .f-xs-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
}
.itas-widget .f-xs-9,
itaswidget .f-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
}
.itas-widget .f-xs-10,
itaswidget .f-xs-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
}
.itas-widget .f-xs-11,
itaswidget .f-xs-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
}
.itas-widget .f-xs-12,
itaswidget .f-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
@media (min-width: 600px) {
  .itas-widget .f-sm-1,
  itaswidget .f-sm-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
  }
  .itas-widget .f-sm-2,
  itaswidget .f-sm-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
  }
  .itas-widget .f-sm-3,
  itaswidget .f-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
  .itas-widget .f-sm-4,
  itaswidget .f-sm-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
  }
  .itas-widget .f-sm-5,
  itaswidget .f-sm-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
  }
  .itas-widget .f-sm-6,
  itaswidget .f-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
  .itas-widget .f-sm-7,
  itaswidget .f-sm-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
  }
  .itas-widget .f-sm-8,
  itaswidget .f-sm-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
  }
  .itas-widget .f-sm-9,
  itaswidget .f-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }
  .itas-widget .f-sm-10,
  itaswidget .f-sm-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
  }
  .itas-widget .f-sm-11,
  itaswidget .f-sm-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
  }
  .itas-widget .f-sm-12,
  itaswidget .f-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media (min-width: 992px) {
  .itas-widget .f-md-1,
  itaswidget .f-md-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
  }
  .itas-widget .f-md-2,
  itaswidget .f-md-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
  }
  .itas-widget .f-md-3,
  itaswidget .f-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
  .itas-widget .f-md-4,
  itaswidget .f-md-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
  }
  .itas-widget .f-md-5,
  itaswidget .f-md-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
  }
  .itas-widget .f-md-6,
  itaswidget .f-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
  .itas-widget .f-md-7,
  itaswidget .f-md-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
  }
  .itas-widget .f-md-8,
  itaswidget .f-md-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
  }
  .itas-widget .f-md-9,
  itaswidget .f-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }
  .itas-widget .f-md-10,
  itaswidget .f-md-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
  }
  .itas-widget .f-md-11,
  itaswidget .f-md-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
  }
  .itas-widget .f-md-12,
  itaswidget .f-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media (min-width: 1200px) {
  .itas-widget .f-lg-1,
  itaswidget .f-lg-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
  }
  .itas-widget .f-lg-2,
  itaswidget .f-lg-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
  }
  .itas-widget .f-lg-3,
  itaswidget .f-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
  .itas-widget .f-lg-4,
  itaswidget .f-lg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
  }
  .itas-widget .f-lg-5,
  itaswidget .f-lg-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
  }
  .itas-widget .f-lg-6,
  itaswidget .f-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
  .itas-widget .f-lg-7,
  itaswidget .f-lg-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
  }
  .itas-widget .f-lg-8,
  itaswidget .f-lg-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
  }
  .itas-widget .f-lg-9,
  itaswidget .f-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }
  .itas-widget .f-lg-10,
  itaswidget .f-lg-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
  }
  .itas-widget .f-lg-11,
  itaswidget .f-lg-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
  }
  .itas-widget .f-lg-12,
  itaswidget .f-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
.itas-widget .f-y,
itaswidget .f-y,
.itas-widget .f-x,
itaswidget .f-x,
.itas-widget .f-y *,
itaswidget .f-y *,
.itas-widget .f-x *,
itaswidget .f-x * {
  -webkit-box-ordinal-group: 101;
  -ms-flex-order: 100;
  order: 100;
}
.itas-widget .f-order-1,
itaswidget .f-order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.itas-widget .f-order-2,
itaswidget .f-order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.itas-widget .f-order-3,
itaswidget .f-order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
.itas-widget .f-order-4,
itaswidget .f-order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}
.itas-widget .f-order-5,
itaswidget .f-order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}
.itas-widget .f-order-6,
itaswidget .f-order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}
.itas-widget .f-order-7,
itaswidget .f-order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}
.itas-widget .f-order-8,
itaswidget .f-order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}
.itas-widget .f-order-9,
itaswidget .f-order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}
.itas-widget .f-order-10,
itaswidget .f-order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}
@media (max-width: 599px) {
  .itas-widget .f-order-xs-1,
  itaswidget .f-order-xs-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .itas-widget .f-order-xs-2,
  itaswidget .f-order-xs-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .itas-widget .f-order-xs-3,
  itaswidget .f-order-xs-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .itas-widget .f-order-xs-4,
  itaswidget .f-order-xs-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .itas-widget .f-order-xs-5,
  itaswidget .f-order-xs-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .itas-widget .f-order-xs-6,
  itaswidget .f-order-xs-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .itas-widget .f-order-xs-7,
  itaswidget .f-order-xs-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .itas-widget .f-order-xs-8,
  itaswidget .f-order-xs-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .itas-widget .f-order-xs-9,
  itaswidget .f-order-xs-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .itas-widget .f-order-xs-10,
  itaswidget .f-order-xs-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
}
@media (max-width: 991px) {
  .itas-widget .f-order-sm-1,
  itaswidget .f-order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .itas-widget .f-order-sm-2,
  itaswidget .f-order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .itas-widget .f-order-sm-3,
  itaswidget .f-order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .itas-widget .f-order-sm-4,
  itaswidget .f-order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .itas-widget .f-order-sm-5,
  itaswidget .f-order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .itas-widget .f-order-sm-6,
  itaswidget .f-order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .itas-widget .f-order-sm-7,
  itaswidget .f-order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .itas-widget .f-order-sm-8,
  itaswidget .f-order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .itas-widget .f-order-sm-9,
  itaswidget .f-order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .itas-widget .f-order-sm-10,
  itaswidget .f-order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
}
@media (max-width: 1199px) {
  .itas-widget .f-order-md-1,
  itaswidget .f-order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .itas-widget .f-order-md-2,
  itaswidget .f-order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .itas-widget .f-order-md-3,
  itaswidget .f-order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .itas-widget .f-order-md-4,
  itaswidget .f-order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .itas-widget .f-order-md-5,
  itaswidget .f-order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .itas-widget .f-order-md-6,
  itaswidget .f-order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .itas-widget .f-order-md-7,
  itaswidget .f-order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .itas-widget .f-order-md-8,
  itaswidget .f-order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .itas-widget .f-order-md-9,
  itaswidget .f-order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .itas-widget .f-order-md-10,
  itaswidget .f-order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
}
.itas-widget .f-order-lg-1,
itaswidget .f-order-lg-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.itas-widget .f-order-lg-2,
itaswidget .f-order-lg-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.itas-widget .f-order-lg-3,
itaswidget .f-order-lg-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
.itas-widget .f-order-lg-4,
itaswidget .f-order-lg-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}
.itas-widget .f-order-lg-5,
itaswidget .f-order-lg-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}
.itas-widget .f-order-lg-6,
itaswidget .f-order-lg-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}
.itas-widget .f-order-lg-7,
itaswidget .f-order-lg-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}
.itas-widget .f-order-lg-8,
itaswidget .f-order-lg-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}
.itas-widget .f-order-lg-9,
itaswidget .f-order-lg-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}
.itas-widget .f-order-lg-10,
itaswidget .f-order-lg-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}
.itas-widget .f-grow-1,
itaswidget .f-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.itas-widget .f-grow-2,
itaswidget .f-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.itas-widget .f-grow-3,
itaswidget .f-grow-3 {
  -webkit-box-flex: 3;
  -ms-flex-positive: 3;
  flex-grow: 3;
}
.itas-widget .f-grow-4,
itaswidget .f-grow-4 {
  -webkit-box-flex: 4;
  -ms-flex-positive: 4;
  flex-grow: 4;
}
.itas-widget .f-grow-5,
itaswidget .f-grow-5 {
  -webkit-box-flex: 5;
  -ms-flex-positive: 5;
  flex-grow: 5;
}
.itas-widget .f-grow,
itaswidget .f-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.itas-widget .f-grow-children > *,
itaswidget .f-grow-children > * {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.itas-widget .f-basis-0,
itaswidget .f-basis-0 {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}
.itas-widget .f-shrink-0,
itaswidget .f-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.itas-widget html,
itaswidget html,
.itas-widget body,
itaswidget body {
  margin: 0;
  min-height: 100vh;
}
.itas-widget *,
itaswidget *,
.itas-widget *:after,
itaswidget *:after,
.itas-widget *:before,
itaswidget *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.itas-widget:not(.initialized) *,
itaswidget:not(.initialized) * {
  opacity: 0;
}
.itas-widget.border,
itaswidget.border {
  border: 1px solid #eaeaea;
}
.itas-widget:before,
itaswidget:before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  border: 3px solid #333;
  border-left-color: transparent;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -15px;
  -webkit-animation: itas-spin 0.5s infinite linear;
  animation: itas-spin 0.5s infinite linear;
}
.itas-widget.initialized,
itaswidget.initialized {
  background: #fff;
  border-color: #26292E;
  color: #333;
}
.itas-widget.initialized.clean,
itaswidget.initialized.clean {
  background: #fff;
  border-color: #d7d7d7;
  color: #333;
}
.itas-widget.initialized.clean .input input,
itaswidget.initialized.clean .input input {
  border: 1px solid #d7d7d7;
}
.itas-widget.initialized:before,
itaswidget.initialized:before {
  content: none;
}
.itas-widget hint,
itaswidget hint {
  z-index: 1;
}
@-webkit-keyframes itas-spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes itas-spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.fagforum-modal {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: fagforumbackground 0.2s ease-out;
  animation: fagforumbackground 0.2s ease-out;
  font-family: 'Open Sans';
  font-size: 19px;
}
.fagforum-modal > * {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  max-height: 100vh;
  overflow-y: auto;
  background: white;
  border-radius: 3px;
  padding: 2em 1.5em;
  -webkit-box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.8);
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.8);
  -webkit-animation: fagforummodal 0.2s ease-out;
  animation: fagforummodal 0.2s ease-out;
}
.fagforum-modal > * > * + * {
  margin-top: 1em;
}
.fagforum-modal > * h2 {
  font-weight: 600;
  font-size: 1em;
  margin: 0;
  text-align: center;
}
.fagforum-modal > * h2 + * {
  margin-top: 1.5em;
}
.fagforum-modal > * button,
.fagforum-modal > * input,
.fagforum-modal > * textarea {
  font-family: inherit;
  font-size: inherit;
  background: transparent;
}
.fagforum-modal > * label {
  font-size: 0.8em;
  font-weight: 300;
  color: #7c8aa7;
}
.fagforum-modal > * label + input,
.fagforum-modal > * label + textarea {
  margin-top: 0.4em;
}
.fagforum-modal > * input,
.fagforum-modal > * textarea {
  padding: 1em;
  outline: none;
  background: #f6f7f9;
  border-radius: 3px;
  border: 1px solid #e7e9ef;
}
.fagforum-modal > * input:focus,
.fagforum-modal > * textarea:focus {
  border-color: #c8ceda;
}
.fagforum-modal > * button {
  cursor: pointer;
  border: none;
  padding: 0.5em 1.5em;
  border-radius: 3px;
}
.fagforum-modal > * button[type="submit"] {
  outline: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5em;
  background: #7ab8ce;
  color: white;
  font-weight: 200;
  -webkit-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}
.fagforum-modal > * button[type="submit"]:hover,
.fagforum-modal > * button[type="submit"]:focus {
  background: #00759c;
}
.fagforum-modal > * button[type="submit"] i {
  margin-left: 0.5em;
}
.fagforum-modal > * button[type="submit"]:disabled {
  cursor: default;
  background: #f6f7f9;
  border: 1px solid #e7e9ef;
  color: inherit;
}
.fagforum-modal > * button[type="submit"].loading {
  cursor: wait;
}
.fagforum-modal > * button[type="submit"].loading i {
  display: none;
}
.fagforum-modal > * button[type="submit"].loading:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 0.5em;
  border-radius: 50%;
  border: 2px solid currentColor;
  border-left-color: transparent;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes fagforummodal {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@keyframes fagforummodal {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@-webkit-keyframes fagforumbackground {
  from {
    opacity: 0;
  }
}
@keyframes fagforumbackground {
  from {
    opacity: 0;
  }
}
.fagforum-modal-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  padding: 1em 1.5em;
  top: 0em;
  right: 0em;
  opacity: 0.75;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
.fagforum-modal-close:hover,
.fagforum-modal-close:focus {
  opacity: 1;
}
.fagforum-modal-close:before {
  content: '\f00d';
  font-family: FontAwesome;
}
.fagforum-modal-categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -0.4em -0.4em;
}
.fagforum-modal-categories input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.fagforum-modal-categories input:focus + label:before {
  border-color: #c8ceda;
}
.fagforum-modal-categories input:checked + label:after {
  content: '\f00c';
  font-family: FontAwesome;
}
.fagforum-modal-categories label {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5em;
}
.fagforum-modal-categories label:before {
  content: '';
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.3em;
  background: #f6f7f9;
  border: 1px solid #e7e9ef;
  border-radius: 3px;
}
.fagforum-modal-categories label:after {
  color: #7ab8ce;
  font-size: 0.6em;
  position: absolute;
  left: 4.5px;
  top: 5px;
}
.godfather-entry {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: 'Open Sans';
  font-size: 16px;
  z-index: 999;
}
.godfather-entry * {
  font-family: inherit;
}
.godfather-entry .godfather-animation {
  -webkit-animation: godfather-enter 200ms ease-out;
  animation: godfather-enter 200ms ease-out;
  line-height: 1.42;
  border-radius: 4px;
  -webkit-box-shadow: 0 15px 25px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 15px 25px -5px rgba(0, 0, 0, 0.4);
}
.godfather-entry .godfather-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.godfather-entry .godfather-inner-container {
  padding: 1em;
  max-width: 300px;
}
.godfather-entry .godfather-actions {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.godfather-entry .godfather-close {
  margin-left: 2em;
}
.godfather-entry button {
  font-size: 0.75em;
  cursor: pointer;
  padding: 5px 15px;
  color: inherit;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  line-height: 1;
  border-radius: 2px;
  -webkit-transition: background 100ms ease-out;
  transition: background 100ms ease-out;
}
.godfather-entry button:hover {
  background: rgba(255, 255, 255, 0.2) !important;
}
.godfather-entry .godfather-image {
  background-position: center;
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.1);
  width: 110px;
}
.godfather-entry .godfather-title {
  font-weight: bold;
}
.godfather-entry hr {
  border: none;
  border-top: 1px solid currentColor;
  opacity: 0.2;
  margin: 0.5em 0;
}
.godfather-entry .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 10px;
  border-color: currentColor;
}
.godfather-entry[x-placement^="top"] {
  margin-bottom: 10px;
}
.godfather-entry[x-placement^="top"] .tooltip-arrow {
  border-width: 10px 10px 0 10px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -10px;
  left: calc(50% - 10px);
  margin-top: 0;
  margin-bottom: 0;
}
.godfather-entry[x-placement^="bottom"] {
  margin-top: 10px;
}
.godfather-entry[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 10px 10px 10px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -10px;
  left: calc(50% - 10px);
  margin-top: 0;
  margin-bottom: 0;
}
.godfather-entry[x-placement^="right"] {
  margin-left: 10px;
}
.godfather-entry[x-placement^="right"] .tooltip-arrow {
  border-width: 10px 10px 10px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -10px;
  top: calc(50% - 10px);
  margin-left: 0;
  margin-right: 0;
}
.godfather-entry[x-placement^="left"] {
  margin-right: 10px;
}
.godfather-entry[x-placement^="left"] .tooltip-arrow {
  border-width: 10px 0 10px 10px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -10px;
  top: calc(50% - 10px);
  margin-left: 0;
  margin-right: 0;
}
.godfather-hint {
  cursor: pointer;
  position: absolute;
  opacity: 0.9;
  top: -5px;
  right: -5px;
  font-family: 'Open Sans';
  border-radius: 50%;
  background: currentColor;
  border: 2px solid currentColor;
  width: 20px;
  height: 20px;
}
.godfather-hint:before {
  content: '?';
  display: block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: relative;
  font-size: 13px;
  z-index: 1;
  color: white;
  text-align: center;
  line-height: 16px;
}
.godfather-hint:after {
  content: '';
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: absolute;
  top: -2px;
  right: -2px;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: currentColor;
  -webkit-animation: pulse 2s infinite ease-out;
  animation: pulse 2s infinite ease-out;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
.godfather-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 8;
}
@-webkit-keyframes godfather-enter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes godfather-enter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
/*Main color*/
/*Datepickk*/
.d-noscroll {
  overflow: hidden;
}
#Datepickk {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  font-family: inherit;
  color: #333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#Datepickk.MSIE:not(.wrapped):not(.inline) .d-calendar {
  height: 560px;
}
#Datepickk.d-show .d-overlay {
  -webkit-animation-name: show;
  animation-name: show;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
#Datepickk.d-hide > * {
  -webkit-animation-name: DatepickkHide;
  animation-name: DatepickkHide;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
#Datepickk.d-hide .d-overlay {
  -webkit-animation-name: show;
  animation-name: show;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-direction: reverse;
}
#Datepickk .d-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 58, 71, 0.95);
  z-index: 1;
}
#Datepickk .d-title {
  color: white;
  text-align: center;
  position: relative;
  font-size: 30px;
  margin: 25px 0;
  z-index: 2;
}
#Datepickk .d-confirm {
  border: none;
  position: relative;
  background-color: transparent;
  outline: none;
  font-family: inherit;
  font-size: 30px;
  font-weight: 500;
  color: white;
  margin: 25px 0;
  cursor: pointer;
  padding: 0;
  z-index: 2;
}
#Datepickk .d-title:empty,
#Datepickk .d-confirm:empty {
  display: none;
}
@media (max-height: 528px) {
  #Datepickk .d-title,
  #Datepickk .d-confirm {
    font-size: 20px;
    margin: 15px 0;
  }
  #Datepickk .d-calendar {
    font-size: 20px;
  }
}
#Datepickk.multi .d-calendar,
#Datepickk.multi .d-legend {
  max-width: 800px;
}
#Datepickk.multi .d-table {
  position: relative;
  padding-top: 35px;
}
#Datepickk.multi .d-table:before {
  content: attr(data-month);
  text-align: right;
  width: 100%;
  font-size: 1em;
  padding: 5px 10px 5px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #CCC;
  position: absolute;
  left: 0;
  top: 0;
}
#Datepickk.inline.d-show .d-calendar {
  -webkit-animation: none;
  animation: none;
}
#Datepickk.inline,
#Datepickk.wrapped {
  position: static;
  z-index: 0;
}
#Datepickk.inline .d-calendar,
#Datepickk.wrapped .d-calendar {
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 0;
  max-width: none;
  max-height: none;
}
#Datepickk.inline .d-confirm,
#Datepickk.wrapped .d-confirm,
#Datepickk.inline .d-title,
#Datepickk.wrapped .d-title {
  color: #222;
}
#Datepickk.inline .d-overlay,
#Datepickk.wrapped .d-overlay {
  display: none;
}
#Datepickk.inline .d-legend,
#Datepickk.wrapped .d-legend {
  color: #222 !important;
  max-width: none;
  max-height: none;
}
#Datepickk.fullscreen .d-calendar {
  max-width: none;
  max-height: none;
}
#Datepickk.fullscreen .d-calendar input + label {
  outline: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
#Datepickk.fullscreen .d-calendar input + label text {
  padding: 5px 10px 0 0;
}
#Datepickk.fullscreen .d-legend {
  max-width: none;
}
#Datepickk .d-legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  background-color: whitesmoke;
  max-width: 500px;
  position: relative;
  color: white;
}
#Datepickk .d-legend:empty {
  height: 0;
}
#Datepickk .d-legend p {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: DatepickfadeInUp;
  animation-name: DatepickfadeInUp;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin: 0;
  padding: 15px;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  cursor: pointer;
  color: #1B353F;
}
#Datepickk .d-legend p:after {
  content: attr(data-legend);
}
#Datepickk .d-legend p span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 10px;
}
#Datepickk .d-calendar {
  margin: 0;
  background-color: white;
  -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.6);
  font-size: 20px;
  width: 100%;
  position: relative;
  max-width: 500px;
  max-height: 560px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  z-index: 2;
}
#Datepickk .d-header {
  position: relative;
  background-color: #1B363F;
  color: white;
  font-size: 1.5em;
}
#Datepickk .d-header p {
  margin: 0.5em 0;
  text-align: center;
}
#Datepickk .d-header i {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 100%;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
#Datepickk .d-header i:before,
#Datepickk .d-header i:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
#Datepickk .d-header i:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
#Datepickk .d-header i:after {
  border-top: 7px solid rgba(0, 0, 0, 0);
  border-bottom: 7px solid rgba(0, 0, 0, 0);
  -webkit-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}
#Datepickk .d-header i#d-previous {
  left: 20px;
}
#Datepickk .d-header i#d-previous:before {
  border-right: 10px solid #FFF;
  top: 5px;
  left: 7px;
}
#Datepickk .d-header i#d-previous:after {
  border-right: 7px solid #1B363F;
  top: 8px;
  left: 10px;
}
#Datepickk .d-header i#d-next {
  right: 20px;
}
#Datepickk .d-header i#d-next:before {
  border-left: 10px solid #FFF;
  top: 5px;
  left: 12px;
}
#Datepickk .d-header i#d-next:after {
  border-left: 7px solid #1B363F;
  top: 8px;
  left: 12px;
}
#Datepickk .d-header i:hover {
  background-color: #5E7178;
}
#Datepickk .d-header i:hover:after {
  border-left-color: #5E7178 !important;
  border-right-color: #5E7178 !important;
}
#Datepickk .d-month {
  cursor: pointer;
  white-space: nowrap;
}
#Datepickk .d-year {
  margin-left: 10px;
  cursor: pointer;
}
#Datepickk .d-year:before {
  content: "";
}
#Datepickk .d-month-picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: rgba(27, 54, 63, 0.9);
  height: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition: height 0.2s ease;
  transition: height 0.2s ease;
}
#Datepickk .d-month-picker.d-show {
  height: 44px;
  pointer-events: auto;
}
#Datepickk .d-month-picker > div {
  width: calc(100% / 12);
  text-align: center;
  line-height: 44px;
  color: white;
  cursor: pointer;
}
#Datepickk .d-month-picker > div:hover,
#Datepickk .d-month-picker > div:focus {
  background-color: #e32d2d;
  color: white;
}
#Datepickk .d-month-picker > div.current {
  background-color: #e9965a;
  color: white;
}
#Datepickk .d-year-picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: rgba(27, 54, 63, 0.9);
  height: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition: height 0.2s ease;
  transition: height 0.2s ease;
}
#Datepickk .d-year-picker.d-show {
  height: 44px;
  pointer-events: auto;
}
#Datepickk .d-year-picker > div {
  width: calc(100% / 11);
  text-align: center;
  line-height: 44px;
  color: white;
  cursor: pointer;
}
#Datepickk .d-year-picker > div:hover,
#Datepickk .d-year-picker > div:focus {
  background-color: #e32d2d;
  color: white;
}
#Datepickk .d-year-picker > div.current {
  background-color: #e9965a;
  color: white;
}
#Datepickk .d-weekdays {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#Datepickk .d-week {
  background-color: #E95A5A;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
#Datepickk .d-week + .d-week {
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}
#Datepickk .d-week > div {
  -ms-flex-preferred-size: calc(100% / 7);
  flex-basis: calc(100% / 7);
  text-align: center;
}
#Datepickk .d-week > div p {
  margin: 0.8em 0;
}
@media (max-height: 540px) {
  #Datepickk .d-week {
    display: none;
  }
}
#Datepickk .d-table {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
#Datepickk .d-table:first-child:nth-last-child(n + 3),
#Datepickk .d-table:first-child:nth-last-child(n + 3) ~ div {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex: 0;
  -ms-flex-preferred-size: calc(100% / 3 - 1px);
  flex-basis: calc(100% / 3 - 1px);
  /*-1px due to browser render*/
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
#Datepickk .d-table label:nth-of-type(7n) .d-date-legends {
  padding-right: 0;
}
#Datepickk .d-table label:nth-last-of-type(-n + 7) .d-date-legends {
  padding-bottom: 0;
}
#Datepickk .d-table input {
  display: none;
}
#Datepickk .d-table input + label {
  flex-basis: calc(100% / 7);
  -ms-flex-preferred-size: 14.28%;
  /*Keep -> IE render fail*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease, background 0.2s ease, color 0.2s ease;
  transition: background-color 0.2s ease, background 0.2s ease, color 0.2s ease;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#Datepickk .d-table input + label text {
  z-index: 9;
  pointer-events: none;
  position: relative;
}
#Datepickk .d-table input + label[style*="linear-gradient"] text {
  text-shadow: 0 0 20px black;
}
#Datepickk .d-table input + label.today text:before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #E95A5A;
  position: absolute;
  bottom: -5px;
  left: 0;
}
#Datepickk .d-table input + label:before {
  content: '';
  position: absolute;
  top: 12.5%;
  left: 12.5%;
  width: 75%;
  height: 75%;
  border-radius: 5px;
  background-color: #7DD076;
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
}
#Datepickk .d-table input + label.legend-hover {
  -webkit-animation: legendhover 1s infinite;
  animation: legendhover 1s infinite;
  z-index: 10;
}
@-webkit-keyframes legendhover {
  0% {
    -webkit-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
  50% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
  }
  100% {
    -webkit-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
}
@keyframes legendhover {
  0% {
    -webkit-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
  50% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
  }
  100% {
    -webkit-transform: translate(0, 0px);
    transform: translate(0, 0px);
  }
}
#Datepickk .d-table input + label.next,
#Datepickk .d-table input + label.prev {
  color: #ccc;
}
#Datepickk .d-table input + label [data-tooltip]:after {
  content: '';
  border-radius: 100%;
  background-color: #1B363F;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 0;
  right: -10px;
}
#Datepickk .d-table input + label .d-tooltip {
  position: absolute;
  background-color: #1B363F;
  color: #FFF;
  padding: 7px;
  font-size: 0.7em;
  z-index: 13;
  text-align: center;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -5px);
  transform: translate(-50%, -5px);
  display: none;
}
#Datepickk .d-table input + label .d-tooltip:before {
  content: '';
  border-bottom: 7px solid #1B363F;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  top: -7px;
  left: 50%;
  position: absolute;
  margin-left: -5px;
}
#Datepickk .d-table input + label .d-tooltip:empty {
  display: none!important;
}
#Datepickk .d-table input + label:hover .d-tooltip {
  display: block;
}
#Datepickk .d-table input:checked + label {
  color: white;
}
#Datepickk .d-table input:checked + label:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
#Datepickk .d-table input:disabled + label {
  cursor: not-allowed;
}
#Datepickk .d-table input:disabled + label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 20%;
  width: 60%;
  height: 2px;
  z-index: 10;
  background-color: #c60000;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(-25deg);
  transform: rotate(-25deg);
}
#Datepickk .d-table input + label.d-hidden {
  cursor: default;
  color: #ccc !important;
  background: #f0f0f0 !important;
  text-decoration: line-through;
}
#Datepickk .d-table input + label.d-hidden:after {
  content: none;
}
#Datepickk .d-tables {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#Datepickk .d-tables:not(.locked) input:not(:checked) + label:not(.hidden):hover {
  color: #222;
  background-color: #eaeaea;
}
#Datepickk .d-tables.locked label {
  cursor: default;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked + label {
  color: white;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked + label ~ label:not(.hidden) {
  color: white;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked + label ~ label:not(.hidden):before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  background-color: rgba(125, 208, 118, 0.5);
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:hover ~ label,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:focus ~ label {
  color: #666;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:hover ~ label:before,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:focus ~ label:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:hover ~ label.next,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:focus ~ label.next,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:hover ~ label.prev,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:focus ~ label.prev {
  color: #ccc;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label {
  color: white;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  background-color: #7DD076;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label ~ label {
  color: inherit;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label ~ label.next,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label ~ label.prev {
  color: #ccc;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label ~ label:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
#Datepickk .d-tables.range.before input:not(.single):not(:checked) + label {
  color: white;
}
#Datepickk .d-tables.range.before input:not(.single):not(:checked) + label:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  background-color: rgba(233, 90, 126, 0.3);
}
#Datepickk .d-tables.range.before input:not(.single):checked + label ~ label {
  color: inherit;
}
#Datepickk .d-tables.range.before input:not(.single):checked + label ~ label.next,
#Datepickk .d-tables.range.before input:not(.single):checked + label ~ label.prev {
  color: #ccc;
}
#Datepickk .d-tables.range.before input:not(.single):checked + label ~ label:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
#Datepickk .d-fadeInUp {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: DatepickfadeInUp;
  animation-name: DatepickfadeInUp;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes DatepickBGShow {
  from {
    opacity: 0;
  }
}
@keyframes DatepickBGShow {
  from {
    opacity: 0;
  }
}
@-webkit-keyframes DatepickfadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes DatepickfadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.d-fadeInUp {
  -webkit-animation-name: DatepickfadeInUp;
  animation-name: DatepickfadeInUp;
}
#Datepickk.d-show > * {
  -webkit-animation-name: DatepickkShow;
  animation-name: DatepickkShow;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes DatepickkShow {
  from {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}
@keyframes DatepickkShow {
  from {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}
@-webkit-keyframes DatepickkHide {
  to {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}
@keyframes DatepickkHide {
  to {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}
